// src/App.js
import React from 'react';
import GlobalStyle from './components/GlobalStyle';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutSection';
import WhyChooseUs from './components/WhyChooseUsSection ';
import CourseSection from './components/CourseSection ';
import FAQs from './components/FAQ';
import PhotoGallery from './components/PhotoGallery';
import ContactUs from './components/ContactUs ';
import Footer from './components/Footer';


function App() {
  return (
    <>
      <GlobalStyle />
      <Header />
      <HeroSection />
      <AboutUs />
      <WhyChooseUs />
      <CourseSection />
      <FAQs />
      <PhotoGallery />
      <ContactUs />
      <Footer />
    </>
  );
}

export default App;
