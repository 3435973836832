import React from 'react';
import styled from 'styled-components';
import { useSpring, animated, config } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate, faBookReader, faChalkboardTeacher, faGlobeEurope } from '@fortawesome/free-solid-svg-icons';
import centerImage from './assets/eh.jpeg';

const SectionContainer = styled.section`
  text-align: center;
  padding: 100px 20px;
`;

const Heading = styled(animated.h1)`
  color: #017BFF;
  font-size: 2.5rem;
  margin-bottom: 32px;
`;

const Description = styled(animated.p)`
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 60px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeatureContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const FeatureIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: #FFC107;
  margin-bottom: 15px;
  transition: color 0.3s ease;

  &:hover {
    color: #017BFF;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  color: #666;
  font-size: 1.1rem;
`;

const FeatureImage = styled(animated.div)`
  width: 100%;
  max-width: 600px;
  height: 400px;
  margin: 20px auto;
  border-radius: 15px;
  overflow: hidden;
  background-image: url(${centerImage});
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    width:270px;
    height:300px;
    
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Button = styled(animated.button)`
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const WhyChooseUs = () => {
  const headingProps = useSpring({ opacity: 1, from: { opacity: 0 }, config: config.slow });
  const descriptionProps = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 100, config: config.slow });

  const featureProps = useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(20px)' },
    delay: 200,
    config: config.molasses,
  });

  const imageProps = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 300 });

  const buttonProps = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(1.05)' },
    reset: true,
    reverse: true,
    delay: 400,
  });

  return (
    <SectionContainer>
      <Heading style={headingProps}>Why Choose Us</Heading>
      <Description style={descriptionProps}>
        Discover the unique advantages our university offers to inspire your <br />
        academic and personal best.
      </Description>
      <ColumnsContainer>
        <Column>
          <FeatureContainer style={featureProps}>
            <FeatureIcon icon={faUserGraduate} />
            <FeatureTitle>Diverse Programs</FeatureTitle>
            <FeatureDescription>
              Offering a wide range of academic disciplines <br />
              tailored to meet global standards.
            </FeatureDescription>
          </FeatureContainer>
          <FeatureContainer style={featureProps}>
            <FeatureIcon icon={faBookReader} />
            <FeatureTitle>Expert Faculty</FeatureTitle>
            <FeatureDescription>
              Learn from world-class educators and <br />
              researchers dedicated to your success.
            </FeatureDescription>
          </FeatureContainer>
        </Column>
        <Column>
          <FeatureImage style={imageProps}>
            <Image src={centerImage} alt="Campus Life" />
          </FeatureImage>
          <ButtonContainer>
            <Button style={buttonProps}>Career Opportunities</Button>
          </ButtonContainer>
        </Column>
        <Column>
          <FeatureContainer style={featureProps}>
            <FeatureIcon icon={faChalkboardTeacher} />
            <FeatureTitle>Innovative Teaching</FeatureTitle>
            <FeatureDescription>
              State-of-the-art methodologies engaging students <br />
              in interactive learning experiences.
            </FeatureDescription>
          </FeatureContainer>
          <FeatureContainer style={featureProps}>
            <FeatureIcon icon={faGlobeEurope} />
            <FeatureTitle>Global Community</FeatureTitle>
            <FeatureDescription>
              Celebrating diversity with students from <br />
              over 100 countries.
            </FeatureDescription>
          </FeatureContainer>
        </Column>
      </ColumnsContainer>
    </SectionContainer>
  );
};

export default WhyChooseUs;
