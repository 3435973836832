import React, { useState } from 'react';
import styled from 'styled-components';
import campusImage from './assets/grad.jpeg';
import { useSpring, animated } from '@react-spring/web';

const SectionContainer = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 100px 20px;
  align-items: flex-start;
  background: linear-gradient(to right, #74ebd5, #ACB6E5);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
  }
`;

const AccordionContainer = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 85%;
  }
`;

const ImageContainer = styled.div`
  width: 45%;
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 80%;
    margin-top: 20px;
  }
`;

const AccordionItem = styled(animated.div)`
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #dfe4ea;
  }
`;

const Question = styled.h3`
  margin: 0;
  font-size: 1.4rem;
  color: #2c3e50;
  font-weight: 500;
`;

const Answer = styled.p`
  font-size: 1rem;
  color: #2c3e50;
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin-top: 15px;
  line-height: 1.5;
  text-align: left;
`;

const Heading = styled.h1`
  font-size: 2.8rem;
  color: #34495e;
  text-align: center;
  margin-bottom: 2rem;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #7f8c8d;
  text-align: center;
  margin-bottom: 30px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const faqs = [
  {
    question: "Is the School Accredited?",
    answer: "The School was approved and registered with the Imo State Ministry of Health in 2008. The Following Accreditation was given to the School after reaching the requirements stipulated by respective regulatory body: Community Health Department by the Community Health Practitioners Registration Board of Nigeria Abuja in 2005, Medical Laboratory Science Department By Medical Laboratory Science Council of Nigeria Abuja in 2011, Environmental Health Technology Department By the West Africa Health Examinations Board Lagos 2014."
  },
  {
    question: "How many departments does the school have?",
    answer: "The School presently has five departments namely: Community Health Department, Medical Laboratory Science Department, Environmental Health Technology Department, Public Health, Pharmacy."
  },
  {
    question: "What are the entry Requirements?",
    answer: "Entrance admission into School Of Health Technology Okporo is written annually during the first quarters of the year at the premises followed by an interview for successful candidates. Entry Requirements vary by department."
  },
  {
    question: "Does the School run Part-time/Weekend Programmes?",
    answer: "No."
  },
  {
    question: "How can I Apply?",
    answer: "Applications can be made through the School directly or through the Admission Portal."
  }
];

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <SectionContainer>
      <AccordionContainer>
        <Heading>FAQs</Heading>
        <Description>Here are some frequently asked questions about our SOHTO and programs.</Description>
        {faqs.map((faq, index) => (
          <AccordionItem key={index} onClick={() => toggleAccordion(index)}>
            <Question>{faq.question}</Question>
            <Answer show={openIndex === index}>{faq.answer}</Answer>
          </AccordionItem>
        ))}
      </AccordionContainer>
      <ImageContainer>
        <img src={campusImage} alt="University Campus" />
      </ImageContainer>
    </SectionContainer>
  );
};

export default FAQs;
