import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import HeroImage from './assets/swearing.jpeg';

const rotateText = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeroWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 60px;
  align-items: center;
  background-color: #ecf0f1;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const TextSection = styled.div`
  h1 {
    color: #34495e;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    animation: ${rotateText} 1s ease forwards;
  }

  .highlight {
    color: #e67e22; /* Vibrant orange color for the dynamic text */
  }

  p {
    color: #7f8c8d;
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  button {
    width:200px;
    padding: 15px 20px;
    border: none;
    border-radius: 8px;
    background-color: #3498db;
    color: white;
    font-weight: bold;
    font-size: 0.9rem;
    margin-right: 15px;

    &:hover {
      background-color: #e67e22; /* Orange color on hover for buttons */
    }

    @media (max-width: 768px) {
      margin: 3px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ImageSection = styled.div`
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const HeroSection = () => {
  const [index, setIndex] = useState(0);
  const phrases = [
    'Empowering Leaders of Tomorrow',
    'Fostering Innovation and Creativity',
    'Building a Brighter Future Together'
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((current) => (current + 1) % phrases.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(intervalId);
  }, [phrases.length]);

  const redirectPortal = () => {
    window.location.href = "https://sohto.com.ng/portal/";
  }
 const redirectPortalNew = () => {
  window.location.href = "https://portal2.sohto.com.ng/"
 }
  return (
    <HeroWrapper>
      <TextSection>
        <h1><span className="highlight">{phrases[index]}</span></h1>
        <p>At SOHTO, we foster a nurturing environment that encourages innovation, critical thinking, and creativity. Join us to embark on a journey of discovery and personal growth, equipped with resources and support to achieve excellence.</p>
        <button onClick={redirectPortal}>Old Portal</button>
        <button onClick={redirectPortalNew}>New Portal</button>
      </TextSection>
      <ImageSection>
        <img src={HeroImage} alt="Campus Life" />
      </ImageSection>
    </HeroWrapper>
  );
};

export default HeroSection;
