import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FiMenu } from 'react-icons/fi';
import logo from './assets/logo.png';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const mobileBreakpoint = '768px';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  background-color: #f8f9fa;
  align-items: center;
  animation: ${fadeIn} 0.5s ease;
`;

const Logo = styled.img`
  height: 50px;
  cursor: pointer;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  @media (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const MobileNav = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  position: fixed; /* Changed to fixed to cover the whole screen */
  top: 0;
  right: 0;
  background-color: #f8f9fa;
  width: 100%;
  height: 100vh; /* Cover the full viewport height */
  padding: 4rem 1rem 1rem; /* Adjusted padding */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Optional shadow */
  z-index: 10; /* Ensure it's on top of other content */
  animation: ${fadeIn} 0.5s ease;

  @media (max-width: ${mobileBreakpoint}) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;

const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1rem;

  &:hover {
    color: #0056b3; /* Updated hover effect */
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  margin-left: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #f1c40f; /* Updated to yellow on hover */
  }

  @media (max-width: ${mobileBreakpoint}) {
    margin: 10px 0;
  }
`;

const MenuIcon = styled(FiMenu)`
  display: none;
  @media (max-width: ${mobileBreakpoint}) {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeaderContainer>
      <Logo src={logo} alt="Logo" />
      <Nav>
        <NavLink href="#home">Home</NavLink>
        <NavLink href="#about">About</NavLink>
        <NavLink href="#career">Career</NavLink>
        <NavLink href="#contact">Contact</NavLink>
      </Nav>
      <MenuIcon onClick={() => setIsOpen(!isOpen)} />
      <MobileNav isOpen={isOpen}>
        <NavLink href="#home" onClick={() => setIsOpen(false)}>Home</NavLink>
        <NavLink href="#about" onClick={() => setIsOpen(false)}>About</NavLink>
        <NavLink href="#career" onClick={() => setIsOpen(false)}>Career</NavLink>
        <NavLink href="#contact" onClick={() => setIsOpen(false)}>Contact</NavLink>
        <Button onClick={() => setIsOpen(false)}>Portal</Button>
        <Button onClick={() => setIsOpen(false)}>Admission</Button>
      </MobileNav>
    </HeaderContainer>
  );
};

export default Header;
