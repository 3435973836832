import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const FooterContainer = styled.footer`
  background-color: #2c3e50;
  color: white;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const SocialMediaLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const Link = styled.a`
  color: white;
  transition: color 0.3s ease;
  &:hover {
    color: #3498db;
  }
`;

// Added media query for mobile view
const CopyrightNotice = styled.p`
  margin: 20px 0 0;
  font-size: 0.9rem;
  text-align: center; // Ensures text is centered by default

  @media (max-width: 768px) {
    text-align: center; 
    margin:10px;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialMediaLinks>
        <Link href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </Link>
        <Link href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </Link>
        <Link href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </Link>
      </SocialMediaLinks>
      <CopyrightNotice>
        &copy; {new Date().getFullYear()} School of Health Technology Okporo. All rights reserved.
      </CopyrightNotice>
    </FooterContainer>
  );
};

export default Footer;
