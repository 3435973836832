import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const SectionContainer = styled.section`
  background-color: #ecf0f1;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CTASection = styled.div`
  background-color: #3498db;
  color: white;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: -50px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const ContactSection = styled(animated.div)`
  background-color: #2c3e50;
  color: white;
  padding: 50px;
  border-radius: 10px;
  margin-top: 30px;
  width: 80%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactHeading = styled.h2`
  font-size: 2.2rem;
  color: #fff;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const FormInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #3498db;
  color: #333;
`;

const FormTextarea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #3498db;
  color: #333;
  resize: none;
`;

const SubmitButton = styled(animated.button)`
  padding: 10px 0;
  border: none;
  background-color: #e74c3c;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #c0392b;
  }
`;

const ContactInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  justify-items: center;
  margin-top: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoItem = styled.div`
  text-align: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #fff;
`;

const ContactUs = () => {
  const contactAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(30px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 500,
  });

  return (
    <SectionContainer>
      <CTASection>
        <h1>Ready to Transform Your Future?</h1>
        <p>Join our vibrant community and start your journey to excellence today.</p>
        <SubmitButton style={{ marginTop: '20px', alignSelf: 'center', width: '100%', maxWidth: '500px' }}>Apply Now</SubmitButton>
      </CTASection>
      <ContactSection style={contactAnimation}>
        <ContactHeading>Contact Us</ContactHeading>
        <FormContainer>
          <ContactForm action="https://formspree.io/f/mrgnoeja" method="POST">
            <FormInput type="text" name="name" placeholder="Your Name" required />
            <FormInput type="email" name="_replyto" placeholder="Your Email" required />
            <FormTextarea name="message" placeholder="Your Message" required />
            <SubmitButton type="submit">Send Message</SubmitButton>
          </ContactForm>
        </FormContainer>
        <ContactInfo>
          <InfoItem>
            <Icon icon={faPhone} />
            <p>+234 70 305 39216<br/>+234 70 339 06637</p>
          </InfoItem>
          <InfoItem>
            <Icon icon={faEnvelope} />
            <p>admin@sohto.com.ng</p>
          </InfoItem>
          <InfoItem>
            <Icon icon={faMapMarkerAlt} />
            <p>School Of Health Technology Okporo-Orlu, Imo State</p>
          </InfoItem>
        </ContactInfo>
      </ContactSection>
    </SectionContainer>
  );
};

export default ContactUs;
