import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserNurse,
  faMicroscope,
  faSeedling,
  faHeartbeat,
  faCapsules,
} from '@fortawesome/free-solid-svg-icons';

const Section = styled.section`
  padding: 100px 20px;
  background-color: #ECF0F1;
`;

const Heading = styled.h1`
  margin-bottom: 2rem;
  color: #34495E;
  text-align: center;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  width: 360px;
  height: 500px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
    background-color: #34495E;
    color: #FFFFFF;
    & svg {
      color: #FFFFFF;
    }
    & button {
      background-color: #FFC107;
      color: #34495E;
    }
  }

  @media (max-width: 768px) {
    width:250px;
    height:300px;
    margin-bottom: 20px; /* Adds margin to the bottom for mobile views */
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: #3498DB;
  margin-bottom: 15px;
`;

const Name = styled.h3`
  font-size: 22px;
  color: #34495E;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 16px;
  color: #7F8C8D;
  text-align: center;
`;

const Button = styled.button`
  border: none;
  background-color: #007BFF;
  color: white;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
`;

const courses = [
  { icon: faUserNurse, name: "Community Health", description: "Empowering communities to achieve better health and wellness." },
  { icon: faMicroscope, name: "Medical Laboratory Science", description: "Unlocking the secrets of health through scientific inquiry." },
  { icon: faSeedling, name: "Environmental Health", description: "Ensuring environmental factors promote health and well-being." },
  { icon: faHeartbeat, name: "Public Health", description: "Protecting and improving community health through education, policy, and research." },
  { icon: faCapsules, name: "Pharmacy", description: "Advancing health through the safe and effective use of medications." },
];

const Courses = () => {
  return (
    <Section>
      <Heading>Our Departments</Heading>
      <CardContainer>
        {courses.map((course, index) => (
          <Card key={index}>
            <Icon icon={course.icon} size="3x" />
            <Name>{course.name}</Name>
            <Description>{course.description}</Description>
            <Button>Learn More</Button>
          </Card>
        ))}
      </CardContainer>
    </Section>
  );
};

export default Courses;
