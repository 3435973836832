// src/components/GlobalStyle.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --blue-dark: #005582;
    --yellow-dark: #fdd835;
    --yellow-light: #fff9c4;
    --text-color: #333;
    --white: #fff;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: var(--white);
    color: var(--text-color);
  }

  button, a {
    cursor: pointer;
    text-decoration: none;
  }
`;

export default GlobalStyle;
