import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from '@react-spring/web';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faUsers, faBookOpen, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import aboutImage from './assets/grad.jpeg'; // Ensure this path matches your file structure

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px;
  gap: 40px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

`;

const TextCard = styled(animated.div)`
  background: linear-gradient(135deg, #009FFF, #ec2F4B);
  color: white;
  padding: 60px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  text-align: center;
  &:hover {
    transform: scale(1.05);
  }
`;

const Achievement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 240px;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
  svg {
    color: #f4d35e;
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 10px;
  }
  p {
    font-size: 1rem;
    color: #666;
  }
`;

const AchievementsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding: 60px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  margin: 60px 0;
`;

const InteractiveImage = styled(animated.div)`
  background-image: url(${aboutImage});
  width: 100%;
  max-width: 800px;
  height: 450px;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.25);
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    width:270px;
    height:300px;
    
  }
`;

const useHover = () => {
  const [props, set] = useSpring(() => ({
    scale: 1,
    config: { mass: 5, tension: 350, friction: 40 },
  }));
  return [props, set];
};

const AboutUs = () => {
  const [textCardProps, textCardHover] = useHover();

  // Define the achievementDetails array here to ensure it's recognized in the scope
  const achievementDetails = [
    { icon: faTrophy, title: "National Champions", description: "Proud winners of the national university championships 3 years in a row." },
    { icon: faUsers, title: "Inclusive Community", description: "A community of students from over 100 countries, embracing diversity." },
    { icon: faBookOpen, title: "Academic Excellence", description: "Home to several Rhodes Scholars and Nobel Laureates." },
    { icon: faGlobeAmericas, title: "Global Impact", description: "Our research contributes to groundbreaking global advancements." },
  ];

  return (
    <>
      <AboutSection>
        <TextCard
          onMouseEnter={() => textCardHover({ scale: 1.05 })}
          onMouseLeave={() => textCardHover({ scale: 1 })}
          style={textCardProps}
        >
          <h2>Welcome to Our SOHTO</h2>
          <p>Explore a campus where opportunity meets innovation in the heart of a vibrant community. Our School prides itself on a tradition of excellence in education, pioneering research, and fostering global leaders.</p>
          <p>Here, you will discover your passion, connect with a diverse group of students, and be challenged by dedicated professors. We are committed to providing an environment where creativity and innovation thrive, preparing you to make a significant impact on the world.</p>
          <p>Join us to embark on your journey of discovery and personal growth. Welcome to a place where futures are bright, and dreams become reality.</p>
        </TextCard>
        <InteractiveImage />
      </AboutSection>
      <AchievementsSection>
        {achievementDetails.map(({ icon, title, description }, index) => (
          <Achievement key={index}>
            <FontAwesomeIcon icon={icon} />
            <h4>{title}</h4>
            <p>{description}</p>
          </Achievement>
        ))}
      </AchievementsSection>
    </>
  );
};

export default AboutUs;
