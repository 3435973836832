import React from 'react';
import styled, { css } from 'styled-components';
import { useTrail, animated } from '@react-spring/web';
import Dike from './assets/dike.jpeg';
import Miriam from './assets/miriam.jpeg';
import San from './assets/sanitarian.jpeg';
import Stan from './assets/stan.jpeg';
import Lady from './assets/lucy.jpeg';

const imageData = [
  {
    src: Dike,
    caption: 'Hon B.E Dike',
    description: 'School Provost',
  },
  {
    src: Miriam,
    caption: 'Sr. Miriam Pat O.',
    description: 'School Administrator',
  },
  {
    src: San,
    caption: 'Sanitarian Ononiwu E.O',
    description: 'HOD Environmental Health Tech.',
  },
  {
    src: Stan,
    caption: 'Engr. Nwosu S.A',
    description: 'Exams and Record Officer',
  },
  {
    src: Lady,
    caption: 'Lady C.E Iroke(JP)',
    description: 'HOD Community Health Tech.',
  },
];

const GallerySection = styled.section`
  padding: 60px 20px;
  background-color: #ecf0f1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`;

const ImageWrapper = styled(animated.div)`
  width: 400px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;

  &:hover {
    & > div {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Adjusting size for mobile view */
  @media (max-width: 768px) {
    width: 100%; /* Adjusts to take the full width of the container */
    height: auto; /* Keeps the aspect ratio of the image */
    max-width: 300px; /* Sets a max-width to ensure images are not too large */
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 152, 219, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
`;

const Caption = styled.h3`
  margin: 0 0 10px 0;
  font-size: 1.8rem;
`;

const Description = styled.p`
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
`;

const PhotoGallery = () => {
  const trail = useTrail(imageData.length, {
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' },
  });

  return (
    <GallerySection>
      {trail.map((animationStyle, index) => (
        <ImageWrapper key={index} style={animationStyle}>
          <Image src={imageData[index].src} alt={`Gallery Image ${index + 1}`} />
          <Overlay>
            <Caption>{imageData[index].caption}</Caption>
            <Description>{imageData[index].description}</Description>
          </Overlay>
        </ImageWrapper>
      ))}
    </GallerySection>
  );
};

export default PhotoGallery;
